// _mock
import { _pricingHome, _tours } from "src/_mock";
// components
import ScrollProgress from "src/components/scroll-progress";
//

import LocationRequestForm from "../components/LocationRequestForm";

// ----------------------------------------------------------------------

export default function LocationRequestView() {
  return (
    <>
      <ScrollProgress />
      <LocationRequestForm />
    </>
  );
}
