import { Helmet } from "react-helmet-async";
// sections
import SignupView from "src/sections/_home/view/SignupView";

// ----------------------------------------------------------------------

export default function SignupPage() {
  return (
    <>
      <Helmet>
        <title>Start Your Journey.</title>
      </Helmet>

      <SignupView />
    </>
  );
}
