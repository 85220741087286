// scroll bar
import "simplebar-react/dist/simplebar.min.css";

// lightbox
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

// slick-carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// lazy image
import "react-lazy-load-image-component/src/effects/blur.css";

// ----------------------------------------------------------------------

import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
// @mui
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// routes
import Router from "src/routes";
// theme
import ThemeProvider from "src/theme";
// components
import ScrollToTop from "src/components/scroll-to-top";
import { ThemeSettings, SettingsProvider } from "src/components/settings";
import MotionLazyContainer from "src/components/animate/MotionLazyContainer";
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { useState } from "react";

// ----------------------------------------------------------------------

export default function App() {
  const createApolloClient = () => {
    return new ApolloClient({
      link: new HttpLink({
        uri: "https://ninja-training.hasura.app/v1/graphql",
        headers: {
          "x-hasura-admin-secret":
            "2xQEzw21drrpSziEEAxrCFI15Z9vu1uOMLuvQr4bt0PaBm7ibKO7G79RQ4Fo3QDe",

          // Authorization: `Bearer 2xQEzw21drrpSziEEAxrCFI15Z9vu1uOMLuvQr4bt0PaBm7ibKO7G79RQ4Fo3QDe`,
        },
      }),
      cache: new InMemoryCache(),
    });
  };
  const [client] = useState(createApolloClient);

  return (
    <ApolloProvider client={client}>
      <HelmetProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SettingsProvider>
            <BrowserRouter>
              <ScrollToTop />
              <ThemeProvider>
                <ThemeSettings>
                  <MotionLazyContainer>
                    <Router />
                  </MotionLazyContainer>
                </ThemeSettings>
              </ThemeProvider>
            </BrowserRouter>
          </SettingsProvider>
        </LocalizationProvider>
      </HelmetProvider>
    </ApolloProvider>
  );
}
