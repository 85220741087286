// _mock
import { _pricingHome, _tours } from "src/_mock";
// components
import ScrollProgress from "src/components/scroll-progress";
//
import PricingHome from "../../pricing/home";
import {
  HomeHero,
  HomeFAQs,
  HomeNewStart,
  HomeForDesigner,
  HomeCombination,
  HomeAdvertisement,
  HomeFeatureHighlights,
  HomeFlexibleComponents,
} from "../components";
import { MarketingLandingFreeSEO } from "src/sections/_marketing/landing";
import { MarketingAboutStory } from "src/sections/_marketing/about";
import {
  TravelLandingHero,
  TravelLandingSummary,
} from "src/sections/_travel/landing";
import RequestInfoFrom from "../components/RequestInfoForm";
import SignupForm from "../components/SignUpForm";

// ----------------------------------------------------------------------

export default function SignupView() {
  return (
    <>
      <ScrollProgress />
      <SignupForm />
    </>
  );
}
