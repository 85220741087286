import { Helmet } from "react-helmet-async";
import LocationRequestView from "src/sections/_home/view/LocationRequestView";
// sections

// ----------------------------------------------------------------------

export default function LocationRequestPage() {
  return (
    <>
      <Helmet>
        <title>Request your Location.</title>
      </Helmet>

      <LocationRequestView />
    </>
  );
}
