export const linkedin = [
  "",
  "",
  "https://www.linkedin.com/in/andycarlson9/",
  "",
  "Co-Founder / CEO",
  "Co-Founder / COO",
  "Chief Technology Officer",
  "Director of Marketing",
];
export const facebook = ["", "", "", "", "", "", "", "", ""];

export const instagram = [
  "",
  "https://instagram.com/lopezkru13?igshid=NTc4MTIwNjQ2YQ==",
  "",
  "",
];

export const twitter = ["", "", "", "", "", "", "", "", ""];
