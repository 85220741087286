import { memo } from "react";

import { Link as RouterLink } from "react-router-dom";
// @mui
import { useTheme } from "@mui/material/styles";
import { Box, BoxProps, Link } from "@mui/material";

// ----------------------------------------------------------------------

interface LogoProps extends BoxProps {
  single?: boolean;
}

function Logo({ single = false, sx }: LogoProps) {
  const theme = useTheme();

  const PRIMARY_MAIN = theme.palette.primary.main;

  const singleLogo = (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="100.000000pt"
      height="100.000000pt"
      viewBox="0 0 300.000000 300.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <metadata>
        Created by potrace 1.10, written by Peter Selinger 2001-2011
      </metadata>
      <g
        transform="translate(0.000000,300.000000) scale(0.200000,-0.200000)"
        fill="#d3d3d3"
        stroke="none"
      >
        <path
          d="M1388 2714 c-2 -2 -29 -6 -59 -9 -29 -4 -61 -9 -69 -12 -8 -3 -28 -7
-44 -10 -46 -8 -137 -39 -207 -72 -162 -75 -290 -209 -348 -366 -31 -81 -31
-84 -40 -210 -12 -186 29 -344 150 -574 33 -63 82 -142 109 -176 46 -58 127
-135 142 -135 10 0 27 -56 19 -66 -3 -5 -23 -22 -43 -37 -44 -33 -58 -73 -58
-172 1 -60 4 -74 25 -98 13 -15 29 -27 36 -27 8 0 6 7 -5 19 -20 23 -38 80
-29 95 4 6 6 24 4 39 -1 15 0 46 4 68 6 35 13 44 56 67 47 26 48 28 42 62 -4
27 -24 52 -86 110 -90 83 -130 141 -211 300 -137 267 -169 498 -98 705 36 103
86 179 170 256 205 187 629 273 902 183 162 -54 277 -152 400 -341 113 -172
148 -327 137 -598 -5 -143 -23 -210 -77 -299 -37 -58 -32 -68 46 -85 l56 -13
37 36 c37 36 53 37 65 0 5 -16 0 -25 -18 -38 -39 -25 -54 -18 -22 10 15 14 26
27 24 30 -5 4 -168 -114 -168 -121 0 -2 26 8 58 22 82 37 139 36 193 -3 106
-77 120 -218 33 -344 l-36 -52 32 23 c68 51 101 172 74 275 -13 51 -69 116
-122 141 -19 9 -32 23 -31 32 3 33 -1 50 -17 65 -22 23 -35 20 -72 -15 -33
-31 -45 -32 -114 -14 -5 2 -11 3 -14 4 -3 0 3 13 14 29 64 93 90 204 86 368
-7 278 -34 400 -115 519 -14 22 -43 66 -64 98 -46 71 -133 162 -200 209 -123
87 -227 118 -412 123 -73 1 -134 1 -135 -1z"
        />
        <path
          d="M1808 2435 c-2 -2 -34 -5 -71 -8 -103 -7 -92 -17 13 -12 96 5 115 9
81 18 -11 2 -21 3 -23 2z"
        />
        <path d="M1620 2410 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z" />
        <path
          d="M1916 2253 c-4 -10 -4 -14 0 -10 4 4 12 3 18 -2 6 -4 43 -18 81 -30
161 -52 215 -123 229 -306 3 -46 2 -60 -3 -40 -6 22 -9 4 -11 -70 l-3 -100 -8
98 c-4 55 -15 120 -24 146 -15 47 -68 121 -87 121 -5 0 6 -18 25 -41 40 -46
55 -97 64 -208 11 -145 -24 -265 -107 -371 -24 -30 -36 -49 -27 -41 11 8 17 9
17 3 0 -23 -157 -145 -239 -185 -80 -40 -89 -42 -191 -45 -108 -4 -238 15
-267 40 -7 6 -13 8 -13 5 0 -9 -100 28 -168 62 -99 50 -171 100 -223 153 -49
51 -109 155 -109 190 0 11 -4 16 -10 13 -20 -12 -12 133 10 175 98 193 574
380 966 380 174 0 244 -20 318 -89 32 -29 35 -31 18 -8 -39 52 -88 81 -163 95
-184 36 -459 7 -708 -74 -57 -19 -110 -34 -117 -34 -8 0 -14 -5 -14 -10 0 -6
-3 -9 -7 -8 -32 6 -190 -82 -252 -142 -80 -76 -105 -127 -105 -211 0 -99 43
-180 153 -290 68 -67 105 -95 176 -131 303 -154 582 -180 755 -70 83 52 170
132 224 205 65 87 92 139 111 215 14 53 15 54 10 12 -6 -45 -54 -156 -96 -219
-38 -57 2 -31 44 29 58 83 79 162 85 317 10 296 -55 414 -264 476 -40 12 -55
12 -64 4 -10 -9 -11 -9 -6 1 4 6 3 12 -2 12 -6 0 -13 -8 -16 -17z m331 -495
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-137 -322 c0 -2 -8 -10
-17 -17 -16 -13 -17 -12 -4 4 13 16 21 21 21 13z"
        />
        <path
          d="M2521 2246 c-7 -8 -15 -33 -18 -56 -5 -39 -7 -40 -44 -40 -47 0 -74
-23 -57 -49 6 -10 33 -28 59 -41 l49 -23 0 -52 c0 -59 19 -87 48 -68 9 5 27
16 38 23 19 12 26 9 67 -20 39 -27 49 -31 62 -20 20 17 19 31 -9 86 l-24 48
34 39 35 39 -22 19 c-15 13 -37 19 -71 19 -37 0 -50 4 -55 18 -4 9 -23 34 -43
55 -32 34 -38 37 -49 23z m150 -126 c33 0 59 -4 59 -9 0 -5 -16 -22 -37 -38
l-36 -30 35 -66 c20 -37 34 -67 31 -67 -2 0 -29 19 -59 42 l-55 41 -32 -31
c-18 -18 -35 -32 -38 -32 -3 0 -3 27 -1 59 l4 60 -68 35 -69 35 63 1 62 0 0
63 0 62 41 -62 42 -63 58 0z"
        />
        <path d="M2060 2076 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z" />
        <path
          d="M2049 1843 c-78 -84 -159 -152 -230 -194 -59 -34 -69 -44 -69 -67 1
-67 49 -79 195 -48 43 9 121 45 142 66 37 36 61 96 69 172 9 91 1 118 -34 118
-21 0 -40 -13 -73 -47z m85 -63 c-2 -80 -21 -129 -65 -163 -44 -33 -145 -66
-224 -74 -57 -5 -64 -4 -74 16 -15 28 -11 33 56 71 88 50 152 100 223 177 76
81 85 78 84 -27z"
        />
        <path
          d="M987 1712 c-10 -10 -17 -31 -17 -46 0 -43 50 -137 99 -186 59 -59
112 -75 219 -66 182 15 272 54 272 120 0 29 -27 44 -107 56 -37 6 -93 17 -123
26 -66 18 -261 93 -274 105 -16 15 -52 10 -69 -9z m143 -53 c120 -51 244 -87
332 -96 74 -6 87 -17 69 -52 -27 -50 -318 -94 -386 -58 -85 44 -184 204 -148
240 13 14 41 6 133 -34z"
        />
        <path
          d="M840 1516 c0 -5 19 -45 43 -89 66 -126 140 -195 277 -261 68 -32 107
-56 99 -60 -8 -3 -4 -3 8 0 13 3 31 0 40 -6 15 -9 14 -10 -7 -5 -20 5 -22 4
-8 -5 9 -6 25 -7 38 -2 22 8 21 9 -11 21 -82 30 -223 107 -265 144 -51 45
-137 149 -183 223 -17 26 -31 44 -31 40z"
        />
        <path
          d="M2127 1346 c-10 -15 -12 -27 -6 -33 6 -6 -7 -29 -38 -64 -26 -30 -41
-48 -32 -42 19 15 30 8 22 -15 -4 -15 -3 -15 6 -2 6 8 11 11 11 6 0 -11 30 14
30 26 0 5 -9 4 -20 -2 -16 -9 -20 -8 -20 5 0 10 5 13 12 9 7 -5 8 -3 3 6 -6
10 -4 12 8 7 11 -4 14 -2 10 9 -6 17 -9 17 51 -1 48 -15 50 -14 83 11 53 41
18 63 -37 24 -21 -15 -37 -19 -56 -14 l-27 7 21 18 c12 9 22 21 22 25 0 4 -5
3 -12 -4 -19 -19 -33 -14 -16 6 26 30 30 42 15 42 -8 0 -22 -11 -30 -24z"
        />
        <path
          d="M256 1344 c-5 -12 -14 -14 -44 -9 -52 9 -72 -13 -47 -51 13 -21 15
-32 7 -49 -14 -30 13 -57 46 -48 15 4 27 -1 41 -18 16 -20 21 -21 34 -10 9 7
19 21 22 32 4 10 14 19 23 19 10 0 23 9 29 20 11 17 9 22 -21 45 -20 15 -36
38 -40 55 -6 33 -39 42 -50 14z m71 -86 l36 -13 -34 -5 c-30 -4 -35 -9 -39
-39 l-5 -34 -14 26 c-16 29 -26 32 -66 17 -28 -11 -27 -3 3 33 14 16 13 20
-13 47 -32 34 -28 37 17 14 33 -17 31 -18 61 31 11 18 12 16 15 -22 3 -38 6
-43 39 -55z"
        />
        <path
          d="M1038 1187 c40 -41 268 -187 292 -187 23 0 -4 21 -105 82 -57 34
-103 64 -101 66 2 1 27 -7 56 -18 90 -36 63 -17 -63 43 l-122 58 43 -44z"
        />
        <path
          d="M2220 1204 c-19 -8 -43 -23 -53 -32 -11 -9 -22 -16 -26 -15 -3 0 -5
-3 -3 -8 1 -5 -4 -9 -11 -9 -8 0 -22 -9 -32 -20 -10 -11 -15 -20 -12 -20 3 0
-1 -16 -9 -36 -9 -20 -13 -47 -10 -60 5 -19 -8 -34 -96 -102 -143 -110 -281
-208 -360 -258 l-68 -41 54 -7 c48 -5 186 6 227 19 10 4 23 -4 33 -20 15 -22
22 -25 49 -20 18 4 37 12 42 17 7 7 -1 8 -23 3 -27 -6 -37 -2 -59 19 -15 15
-33 24 -42 21 -22 -6 -151 -25 -178 -25 -36 0 -27 18 23 48 25 15 58 37 72 49
15 13 30 23 34 23 4 0 13 6 20 13 7 7 42 34 78 59 36 25 91 69 123 97 32 28
62 51 66 51 5 0 16 -17 24 -37 12 -32 57 -87 57 -71 0 3 -13 29 -30 59 -52 94
-33 186 49 244 43 29 76 34 156 22 22 -3 44 -6 48 -6 5 -1 5 -5 2 -11 -3 -5
-1 -10 5 -10 6 0 8 -5 4 -12 -5 -8 -2 -9 9 -5 19 7 33 -9 15 -17 -7 -2 -2 -3
12 -2 14 2 23 7 20 12 -3 5 -8 8 -11 7 -3 -1 -19 11 -34 27 -16 17 -35 30 -42
30 -7 0 -13 6 -13 14 0 7 -14 16 -31 20 -40 8 -38 8 -79 -10z"
        />
        <path
          d="M1944 1116 c-88 -90 -190 -163 -286 -204 -53 -22 -78 -27 -158 -27
-80 0 -101 4 -135 23 -22 12 -43 22 -47 22 -4 0 -36 14 -70 30 -71 35 -75 36
-57 14 17 -21 111 -68 190 -95 47 -16 89 -22 157 -23 50 0 92 -1 92 -2 0 -10
-112 -84 -127 -84 -12 0 -51 13 -88 30 -75 34 -79 22 -5 -15 60 -31 66 -39 41
-64 l-21 -21 -25 28 c-14 15 -23 33 -20 39 2 7 1 12 -3 11 -4 -2 -21 12 -39
30 l-32 32 -88 -57 c-281 -182 -718 -395 -855 -418 -60 -10 -44 2 65 45 136
55 452 216 606 309 72 44 138 78 145 75 8 -3 32 7 55 21 22 15 52 28 66 29 14
0 25 2 25 2 0 1 -12 10 -27 20 -24 15 -26 15 -14 1 7 -10 9 -17 3 -17 -6 0
-38 27 -72 61 -60 58 -62 60 -85 45 -23 -15 -24 -14 -45 20 -12 19 -24 33 -27
30 -6 -6 22 -50 69 -108 45 -54 44 -55 -55 -78 -60 -14 -78 -10 -59 13 15 17
26 87 15 87 -12 0 -47 -104 -41 -121 5 -14 48 -20 63 -9 16 12 65 18 65 8 0
-5 -17 -16 -37 -24 -21 -9 -62 -32 -91 -51 -70 -46 -347 -188 -492 -253 -194
-86 -203 -92 -178 -116 18 -18 35 -19 123 -6 39 6 88 14 110 17 135 21 362
109 556 215 70 39 131 70 135 70 5 0 35 18 67 41 47 32 62 38 73 28 8 -6 14
-15 14 -20 0 -5 -39 -35 -87 -66 -49 -32 -90 -60 -93 -63 -3 -3 -18 -13 -35
-22 -235 -135 -268 -159 -250 -188 3 -5 18 -10 32 -10 29 0 292 86 377 124 32
14 60 26 62 26 2 0 4 -13 4 -29 0 -61 32 -131 78 -172 53 -46 71 -44 23 3 -45
44 -62 82 -68 149 -8 80 0 102 43 122 20 10 34 21 31 24 -10 10 -67 -18 -78
-38 -5 -11 -36 -32 -68 -48 -74 -38 -261 -103 -277 -97 -7 3 -30 -3 -51 -13
-21 -10 -54 -22 -73 -25 -33 -7 -31 -5 20 25 247 143 449 278 645 429 126 98
391 330 388 340 -1 5 11 1 28 -7 24 -12 28 -18 18 -29 -6 -8 -16 -14 -20 -14
-9 0 -158 -110 -185 -135 -7 -6 -25 -22 -40 -36 -16 -13 -36 -33 -44 -44 -20
-26 70 38 214 154 l119 95 -34 14 c-19 8 -39 18 -44 22 -6 5 -42 -24 -91 -74z
m-774 -183 c0 -5 14 -22 30 -40 30 -32 26 -50 -8 -39 -20 7 -64 71 -55 80 9 9
33 7 33 -1z m90 -88 c0 -2 -15 -12 -32 -20 l-33 -16 24 21 c22 19 41 26 41 15z
m-70 -13 c0 -10 -50 -35 -57 -28 -3 2 8 11 24 20 34 18 33 18 33 8z m150 -58
l35 -10 -105 -69 c-58 -38 -177 -106 -265 -150 -202 -102 -350 -150 -520 -169
-48 -6 -46 -5 25 17 103 33 153 54 315 134 149 74 172 87 278 152 38 23 73 38
79 35 7 -5 8 -2 3 6 -6 10 -4 12 8 7 9 -3 17 -1 20 6 2 6 6 4 10 -6 4 -11 1
-17 -8 -17 -8 0 -15 -4 -15 -10 0 -16 6 -12 44 24 39 36 45 49 19 39 -14 -5
-13 -3 3 14 10 11 24 17 29 14 6 -3 26 -11 45 -17z"
        />
        <path
          d="M1889 1133 c-13 -17 -13 -17 6 -3 11 8 22 16 24 17 2 2 0 3 -6 3 -6
0 -17 -8 -24 -17z"
        />
        <path
          d="M1800 1100 c-8 -5 -10 -10 -5 -10 6 0 17 5 25 10 8 5 11 10 5 10 -5
0 -17 -5 -25 -10z"
        />
        <path d="M1848 1084 c-38 -20 -36 -28 2 -9 17 9 30 18 30 20 0 7 -1 6 -32 -11z" />
        <path
          d="M1985 1033 c-242 -199 -580 -436 -790 -552 -60 -34 -105 -61 -99 -61
17 0 134 55 202 95 34 20 64 35 67 35 3 0 12 5 20 12 27 25 40 34 90 62 50 29
127 83 155 109 8 8 25 19 37 26 12 6 27 17 35 24 7 7 48 39 90 71 43 32 81 62
84 67 3 5 24 21 46 35 50 32 104 80 130 117 11 15 18 27 16 27 -2 0 -39 -30
-83 -67z"
        />
        <path
          d="M1351 1080 c-21 -5 -15 -9 35 -24 33 -10 69 -16 80 -13 16 4 16 5 -3
6 -13 0 -23 6 -23 12 0 5 -4 8 -9 4 -5 -3 -12 -1 -16 5 -4 6 -11 7 -17 4 -6
-4 -13 -2 -17 3 -3 5 -17 7 -30 3z"
        />
        <path
          d="M2405 1080 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
        />
        <path
          d="M2453 1073 c0 -12 5 -19 9 -17 4 3 8 -2 8 -10 0 -9 7 -16 15 -16 21
0 19 7 -10 37 -23 25 -24 25 -22 6z"
        />
        <path
          d="M690 1057 c-46 -41 -52 -53 -38 -68 7 -8 19 -27 26 -42 10 -23 15
-26 31 -17 11 5 29 10 40 10 20 0 46 32 35 43 -3 3 -1 14 5 25 11 21 2 33 -46
60 -28 15 -23 16 -53 -11z m69 -31 c23 5 24 4 8 -6 -13 -9 -17 -19 -13 -36 7
-25 -7 -33 -20 -13 -5 8 -13 4 -26 -12 l-20 -24 6 27 c5 21 2 28 -16 34 l-23
8 25 6 c17 4 26 14 29 30 l3 25 11 -22 c8 -18 16 -21 36 -17z"
        />
        <path d="M1790 1050 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z" />
        <path
          d="M2498 1026 c-11 -8 -13 -17 -7 -28 6 -12 3 -33 -10 -69 l-20 -52 24
23 c34 32 49 75 39 110 -8 25 -11 27 -26 16z"
        />
        <path
          d="M1702 998 c-35 -16 -74 -31 -87 -32 -13 0 -22 -3 -20 -7 2 -3 -13 -6
-33 -6 -49 -2 -158 -26 -169 -37 -14 -15 160 1 224 22 34 11 75 30 91 43 16
13 38 29 48 36 32 20 12 13 -54 -19z"
        />
        <path
          d="M2256 831 c-3 -5 -25 -7 -48 -4 -48 7 -46 -10 5 -30 l31 -13 -31 -24
-32 -23 19 -28 c18 -25 18 -27 3 -33 -10 -4 -24 1 -37 15 -17 18 -18 21 -4 16
12 -4 15 -2 11 8 -3 8 -10 12 -17 8 -6 -3 1 6 17 22 43 44 31 53 -13 10 -23
-22 -36 -41 -30 -43 24 -8 5 -24 -81 -67 -65 -33 -89 -50 -85 -61 3 -7 8 -20
11 -27 4 -8 -8 -18 -31 -26 -60 -22 -97 -71 -54 -71 27 0 70 -43 70 -70 0 -12
-7 -35 -16 -53 l-17 -32 21 19 c12 10 26 34 32 52 l12 34 44 -57 c36 -47 44
-64 44 -99 0 -51 14 -91 42 -121 30 -32 43 -29 20 5 -28 39 -45 99 -38 133 4
22 -1 35 -21 56 -14 16 -48 62 -75 103 -41 62 -48 69 -43 42 l7 -33 -27 25
-26 25 20 20 20 20 73 -102 c40 -56 81 -114 90 -129 9 -15 32 -35 50 -44 l33
-17 -38 44 c-20 24 -54 69 -74 99 -21 30 -53 72 -72 93 -28 30 -35 47 -37 83
l-2 45 80 40 c78 39 79 39 108 23 24 -15 31 -15 44 -5 13 11 21 9 50 -15 19
-16 33 -33 32 -39 -3 -12 19 -35 34 -35 7 0 10 -7 6 -15 -3 -9 0 -15 9 -15 8
0 14 -6 12 -12 -1 -7 3 -12 10 -11 7 2 10 -4 7 -12 -4 -9 0 -15 10 -15 10 0
16 -9 16 -24 0 -13 4 -26 10 -28 14 -4 30 -98 17 -98 -6 0 -8 -3 -5 -6 4 -3 2
-20 -3 -37 l-8 -32 23 29 c13 16 26 45 30 65 6 40 -8 103 -27 123 -7 7 -24 32
-40 56 -15 23 -30 42 -33 42 -3 0 -2 -5 2 -12 5 -7 3 -8 -5 -4 -7 5 -10 15 -6
24 3 9 3 13 -1 10 -8 -9 -64 48 -83 85 -8 16 -20 26 -28 23 -7 -3 -13 -1 -13
4 0 6 -7 10 -16 10 -10 0 -2 16 27 50 23 28 45 50 49 50 4 0 31 -23 59 -52
106 -106 165 -266 141 -382 -6 -30 -22 -71 -36 -92 -33 -48 -24 -54 13 -9 54
65 67 158 38 270 -22 82 -60 149 -129 222 -45 49 -65 63 -87 63 -15 0 -30 -4
-33 -9z"
        />
        <path
          d="M1476 493 c-3 -32 -4 -74 0 -93 8 -41 39 -96 59 -103 9 -4 7 6 -8 31
-39 65 -26 211 17 185 5 -3 6 -1 2 5 -4 7 -15 12 -25 12 -9 0 -22 5 -28 11 -7
7 -12 -7 -17 -48z"
        />
        <path
          d="M1885 540 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M1734 525 c-125 -20 -129 -25 -16 -19 115 6 116 5 86 -64 -9 -20 -17
-46 -20 -56 -2 -11 -7 -22 -12 -25 -12 -8 -23 -41 -14 -41 12 0 57 50 72 79
21 40 29 82 23 112 -6 32 -2 32 -119 14z"
        />
        <path
          d="M1870 396 c0 -18 -57 -86 -72 -86 -5 0 -6 6 -2 13 5 8 2 8 -9 -2 -23
-18 -22 -21 12 -21 17 0 39 10 56 28 19 18 29 23 32 15 3 -7 9 -13 14 -13 6 0
7 5 4 10 -3 6 1 10 9 10 24 0 19 14 -14 38 -23 18 -30 19 -30 8z"
        />
        <path
          d="M2126 227 c6 -39 29 -97 39 -97 2 0 3 21 2 47 -1 33 -8 53 -25 69
l-22 23 6 -42z"
        />
        <path d="M2305 220 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z" />
      </g>
    </svg>
  );

  const fullLogo = (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="70.000000pt"
      height="70.000000pt"
      viewBox="0 0 300.000000 300.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <metadata>
        Created by potrace 1.10, written by Peter Selinger 2001-2011
      </metadata>
      <g
        transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M1365 2776 c-136 -27 -284 -94 -385 -173 -145 -115 -256 -297 -295
-482 -19 -89 -19 -132 -2 -156 12 -16 15 -11 21 46 21 213 137 435 293 564 81
67 220 137 328 166 96 26 288 30 403 10 89 -16 104 -9 30 14 -72 23 -299 29
-393 11z"
        />
        <path
          d="M400 2737 c-9 -7 -26 -29 -39 -49 -12 -21 -40 -47 -61 -59 -22 -12
-42 -29 -45 -36 -8 -23 13 -52 55 -73 24 -12 45 -33 55 -54 34 -71 65 -71 107
-1 16 26 42 52 61 61 18 9 39 25 46 36 11 17 9 24 -9 43 -12 13 -34 28 -49 34
-14 6 -35 28 -46 48 -26 47 -54 66 -75 50z m44 -69 c20 -50 24 -54 74 -68 28
-8 52 -17 52 -21 0 -3 -24 -13 -54 -22 -48 -16 -55 -21 -70 -60 -9 -23 -16
-48 -16 -55 0 -29 -17 -8 -34 42 -18 51 -21 54 -72 72 -52 17 -71 34 -39 34 8
0 33 7 55 16 35 14 42 22 57 70 9 30 19 52 21 49 3 -3 15 -29 26 -57z"
        />
        <path
          d="M2945 2690 c-52 -54 -103 -69 -202 -63 -132 10 -215 6 -276 -12 -72
-21 -69 -30 4 -14 36 8 105 10 211 5 131 -5 163 -4 195 9 39 16 97 66 114 98
16 29 -5 19 -46 -23z"
        />
        <path
          d="M1660 2714 c-14 -2 -59 -9 -100 -15 -290 -42 -526 -180 -682 -399
-65 -92 -181 -331 -167 -345 11 -11 32 20 94 144 130 254 250 383 452 485 81
40 245 84 388 102 155 20 198 33 105 32 -36 0 -76 -2 -90 -4z"
        />
        <path
          d="M2965 2575 c-50 -41 -117 -58 -199 -51 -185 16 -238 18 -302 10 -69
-8 -68 -8 66 -15 74 -4 149 -9 165 -12 17 -4 55 -7 85 -9 30 -1 58 -7 62 -13
5 -6 8 -5 8 3 0 6 7 12 16 12 32 0 134 62 134 81 0 23 1 24 -35 -6z"
        />
        <path
          d="M720 1652 c-1 -48 109 -408 157 -512 79 -174 242 -261 533 -285 103
-9 103 0 0 15 -324 47 -459 143 -541 389 -28 84 -24 83 30 -5 59 -95 123 -158
179 -175 34 -10 52 -9 106 5 81 20 116 20 208 0 79 -17 265 -20 333 -5 32 7
20 9 -65 10 -71 0 -150 9 -243 28 -127 25 -142 26 -195 14 -135 -32 -162 -25
-229 56 -85 103 -134 224 -134 331 0 35 2 66 4 69 3 2 22 -4 44 -15 103 -53
347 -112 460 -112 138 0 501 61 608 102 60 23 69 34 16 18 -17 -5 -112 -20
-209 -34 -97 -15 -188 -28 -202 -31 -14 -3 -79 -8 -145 -11 -148 -8 -260 10
-426 66 -64 22 -123 40 -132 40 -8 0 -25 -12 -37 -27 -18 -23 -21 -38 -18 -95
2 -37 0 -64 -5 -59 -4 4 -23 51 -41 104 -36 100 -55 142 -56 119z"
        />
        <path
          d="M690 1613 c0 -85 68 -352 133 -523 38 -101 112 -250 153 -310 126
-185 277 -298 417 -315 l42 -5 -48 14 c-99 29 -160 68 -247 156 -170 172 -308
468 -404 868 -19 79 -37 146 -40 150 -3 3 -6 -13 -6 -35z"
        />
        <path
          d="M1693 1431 c-145 -42 -263 -79 -263 -82 0 -4 9 -9 20 -12 15 -4 20
-14 20 -37 0 -75 91 -149 167 -137 48 8 100 45 119 85 16 34 16 109 0 143 -5
12 14 24 94 59 56 24 103 47 106 52 3 4 4 7 2 7 -2 -1 -121 -36 -265 -78z m-5
-73 c7 -7 12 -21 12 -33 0 -12 -5 -26 -12 -33 -7 -7 -21 -12 -33 -12 -12 0
-26 5 -33 12 -7 7 -12 21 -12 33 0 12 5 26 12 33 7 7 21 12 33 12 12 0 26 -5
33 -12z m-111 -28 c-8 -30 38 -80 73 -80 31 0 80 34 80 56 0 8 5 14 10 14 29
0 -3 -87 -42 -114 -67 -47 -188 17 -188 100 0 33 9 40 54 43 14 1 17 -4 13
-19z"
        />
        <path
          d="M960 1453 c0 -5 9 -16 20 -26 16 -14 20 -29 20 -82 0 -38 6 -78 15
-94 21 -42 72 -81 104 -81 57 0 111 70 111 142 0 33 -2 34 -126 88 -154 67
-144 64 -144 53z m114 -125 c-6 -34 -3 -43 22 -69 15 -16 35 -29 43 -29 14 -1
14 -2 -1 -10 -41 -24 -98 38 -98 107 0 44 6 54 26 46 10 -4 12 -16 8 -45z m96
2 l25 -20 -25 -20 c-31 -25 -54 -22 -58 8 -8 53 16 65 58 32z"
        />
        <path
          d="M1795 1139 c-38 -17 -39 -18 -12 -19 15 0 37 9 49 20 27 24 16 24
-37 -1z"
        />
        <path
          d="M928 1004 c15 -28 90 -96 143 -130 71 -45 174 -75 277 -81 67 -4 92
-3 92 6 0 6 10 15 22 20 14 5 -17 10 -93 14 -185 10 -296 49 -396 140 -31 29
-51 43 -45 31z"
        />
        <path
          d="M970 922 c0 -5 32 -40 72 -77 125 -116 260 -164 457 -165 57 0 102 2
100 4 -2 2 -47 6 -99 10 -52 3 -113 11 -135 16 -22 6 -48 13 -59 15 -27 6 -33
15 -10 16 50 2 110 11 124 19 10 6 -1 7 -30 4 -67 -9 -186 12 -257 46 -34 16
-85 50 -113 76 -27 26 -50 42 -50 36z"
        />
        <path
          d="M1038 804 c27 -51 140 -147 217 -185 71 -36 91 -41 175 -46 98 -6
120 0 40 12 -106 14 -163 29 -183 47 -20 18 -20 19 -2 12 28 -11 226 -14 250
-4 13 6 -15 9 -75 9 -162 2 -284 44 -384 132 -27 24 -44 34 -38 23z"
        />
        <path
          d="M2437 769 c-19 -11 -57 -81 -76 -141 -14 -42 -35 -58 -108 -82 -57
-19 -103 -50 -103 -70 0 -23 44 -55 115 -83 75 -30 70 -24 130 -155 45 -98 83
-86 134 41 25 64 51 94 91 106 48 14 119 55 129 74 14 26 -28 62 -106 90 -74
28 -84 37 -118 122 -31 79 -61 112 -88 98z m53 -110 c18 -50 39 -96 46 -103 7
-7 53 -28 103 -46 50 -18 91 -35 91 -39 0 -3 -43 -21 -95 -40 -52 -18 -98 -38
-103 -45 -4 -6 -23 -54 -43 -106 l-37 -95 -14 35 c-8 19 -26 65 -39 103 l-24
67 -90 35 c-49 19 -96 35 -102 35 -35 0 -4 19 80 49 50 19 97 38 103 42 5 5
26 51 45 103 18 53 37 96 40 96 4 0 21 -41 39 -91z"
        />
        <path
          d="M1215 590 c25 -28 150 -88 200 -95 27 -5 36 -3 26 3 -19 14 -5 32 27
33 15 0 36 4 47 9 12 5 -5 6 -45 3 -78 -7 -154 8 -224 43 -46 23 -49 24 -31 4z"
        />
      </g>
    </svg>
    //     <svg
    //       version="1.0"
    //       xmlns="http://www.w3.org/2000/svg"
    //       width="150.000000pt"
    //       height="150.000000pt"
    //       viewBox="0 0 300.000000 300.000000"
    //       preserveAspectRatio="xMidYMid meet"
    //     >
    //       <metadata>
    //         Created by potrace 1.10, written by Peter Selinger 2001-2011
    //       </metadata>
    //       <g
    //         transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
    //         fill="#000000"
    //         stroke="none"
    //       >
    //         <path
    //           d="M1365 2776 c-136 -27 -284 -94 -385 -173 -145 -115 -256 -297 -295
    // -482 -19 -89 -19 -132 -2 -156 12 -16 15 -11 21 46 21 213 137 435 293 564 81
    // 67 220 137 328 166 96 26 288 30 403 10 89 -16 104 -9 30 14 -72 23 -299 29
    // -393 11z"
    //         />
    //         <path
    //           d="M400 2737 c-9 -7 -26 -29 -39 -49 -12 -21 -40 -47 -61 -59 -22 -12
    // -42 -29 -45 -36 -8 -23 13 -52 55 -73 24 -12 45 -33 55 -54 34 -71 65 -71 107
    // -1 16 26 42 52 61 61 18 9 39 25 46 36 11 17 9 24 -9 43 -12 13 -34 28 -49 34
    // -14 6 -35 28 -46 48 -26 47 -54 66 -75 50z m44 -69 c20 -50 24 -54 74 -68 28
    // -8 52 -17 52 -21 0 -3 -24 -13 -54 -22 -48 -16 -55 -21 -70 -60 -9 -23 -16
    // -48 -16 -55 0 -29 -17 -8 -34 42 -18 51 -21 54 -72 72 -52 17 -71 34 -39 34 8
    // 0 33 7 55 16 35 14 42 22 57 70 9 30 19 52 21 49 3 -3 15 -29 26 -57z"
    //         />
    //         <path
    //           d="M2945 2690 c-52 -54 -103 -69 -202 -63 -132 10 -215 6 -276 -12 -72
    // -21 -69 -30 4 -14 36 8 105 10 211 5 131 -5 163 -4 195 9 39 16 97 66 114 98
    // 16 29 -5 19 -46 -23z"
    //         />
    //         <path
    //           d="M1660 2714 c-14 -2 -59 -9 -100 -15 -290 -42 -526 -180 -682 -399
    // -65 -92 -181 -331 -167 -345 11 -11 32 20 94 144 130 254 250 383 452 485 81
    // 40 245 84 388 102 155 20 198 33 105 32 -36 0 -76 -2 -90 -4z"
    //         />
    //         <path
    //           d="M2965 2575 c-50 -41 -117 -58 -199 -51 -185 16 -238 18 -302 10 -69
    // -8 -68 -8 66 -15 74 -4 149 -9 165 -12 17 -4 55 -7 85 -9 30 -1 58 -7 62 -13
    // 5 -6 8 -5 8 3 0 6 7 12 16 12 32 0 134 62 134 81 0 23 1 24 -35 -6z"
    //         />
    //         <path
    //           d="M720 1652 c-1 -48 109 -408 157 -512 79 -174 242 -261 533 -285 103
    // -9 103 0 0 15 -324 47 -459 143 -541 389 -28 84 -24 83 30 -5 59 -95 123 -158
    // 179 -175 34 -10 52 -9 106 5 81 20 116 20 208 0 79 -17 265 -20 333 -5 32 7
    // 20 9 -65 10 -71 0 -150 9 -243 28 -127 25 -142 26 -195 14 -135 -32 -162 -25
    // -229 56 -85 103 -134 224 -134 331 0 35 2 66 4 69 3 2 22 -4 44 -15 103 -53
    // 347 -112 460 -112 138 0 501 61 608 102 60 23 69 34 16 18 -17 -5 -112 -20
    // -209 -34 -97 -15 -188 -28 -202 -31 -14 -3 -79 -8 -145 -11 -148 -8 -260 10
    // -426 66 -64 22 -123 40 -132 40 -8 0 -25 -12 -37 -27 -18 -23 -21 -38 -18 -95
    // 2 -37 0 -64 -5 -59 -4 4 -23 51 -41 104 -36 100 -55 142 -56 119z"
    //         />
    //         <path
    //           d="M690 1613 c0 -85 68 -352 133 -523 38 -101 112 -250 153 -310 126
    // -185 277 -298 417 -315 l42 -5 -48 14 c-99 29 -160 68 -247 156 -170 172 -308
    // 468 -404 868 -19 79 -37 146 -40 150 -3 3 -6 -13 -6 -35z"
    //         />
    //         <path
    //           d="M1693 1431 c-145 -42 -263 -79 -263 -82 0 -4 9 -9 20 -12 15 -4 20
    // -14 20 -37 0 -75 91 -149 167 -137 48 8 100 45 119 85 16 34 16 109 0 143 -5
    // 12 14 24 94 59 56 24 103 47 106 52 3 4 4 7 2 7 -2 -1 -121 -36 -265 -78z m-5
    // -73 c7 -7 12 -21 12 -33 0 -12 -5 -26 -12 -33 -7 -7 -21 -12 -33 -12 -12 0
    // -26 5 -33 12 -7 7 -12 21 -12 33 0 12 5 26 12 33 7 7 21 12 33 12 12 0 26 -5
    // 33 -12z m-111 -28 c-8 -30 38 -80 73 -80 31 0 80 34 80 56 0 8 5 14 10 14 29
    // 0 -3 -87 -42 -114 -67 -47 -188 17 -188 100 0 33 9 40 54 43 14 1 17 -4 13
    // -19z"
    //         />
    //         <path
    //           d="M960 1453 c0 -5 9 -16 20 -26 16 -14 20 -29 20 -82 0 -38 6 -78 15
    // -94 21 -42 72 -81 104 -81 57 0 111 70 111 142 0 33 -2 34 -126 88 -154 67
    // -144 64 -144 53z m114 -125 c-6 -34 -3 -43 22 -69 15 -16 35 -29 43 -29 14 -1
    // 14 -2 -1 -10 -41 -24 -98 38 -98 107 0 44 6 54 26 46 10 -4 12 -16 8 -45z m96
    // 2 l25 -20 -25 -20 c-31 -25 -54 -22 -58 8 -8 53 16 65 58 32z"
    //         />
    //         <path
    //           d="M1795 1139 c-38 -17 -39 -18 -12 -19 15 0 37 9 49 20 27 24 16 24
    // -37 -1z"
    //         />
    //         <path
    //           d="M928 1004 c15 -28 90 -96 143 -130 71 -45 174 -75 277 -81 67 -4 92
    // -3 92 6 0 6 10 15 22 20 14 5 -17 10 -93 14 -185 10 -296 49 -396 140 -31 29
    // -51 43 -45 31z"
    //         />
    //         <path
    //           d="M970 922 c0 -5 32 -40 72 -77 125 -116 260 -164 457 -165 57 0 102 2
    // 100 4 -2 2 -47 6 -99 10 -52 3 -113 11 -135 16 -22 6 -48 13 -59 15 -27 6 -33
    // 15 -10 16 50 2 110 11 124 19 10 6 -1 7 -30 4 -67 -9 -186 12 -257 46 -34 16
    // -85 50 -113 76 -27 26 -50 42 -50 36z"
    //         />
    //         <path
    //           d="M1038 804 c27 -51 140 -147 217 -185 71 -36 91 -41 175 -46 98 -6
    // 120 0 40 12 -106 14 -163 29 -183 47 -20 18 -20 19 -2 12 28 -11 226 -14 250
    // -4 13 6 -15 9 -75 9 -162 2 -284 44 -384 132 -27 24 -44 34 -38 23z"
    //         />
    //         <path
    //           d="M2437 769 c-19 -11 -57 -81 -76 -141 -14 -42 -35 -58 -108 -82 -57
    // -19 -103 -50 -103 -70 0 -23 44 -55 115 -83 75 -30 70 -24 130 -155 45 -98 83
    // -86 134 41 25 64 51 94 91 106 48 14 119 55 129 74 14 26 -28 62 -106 90 -74
    // 28 -84 37 -118 122 -31 79 -61 112 -88 98z m53 -110 c18 -50 39 -96 46 -103 7
    // -7 53 -28 103 -46 50 -18 91 -35 91 -39 0 -3 -43 -21 -95 -40 -52 -18 -98 -38
    // -103 -45 -4 -6 -23 -54 -43 -106 l-37 -95 -14 35 c-8 19 -26 65 -39 103 l-24
    // 67 -90 35 c-49 19 -96 35 -102 35 -35 0 -4 19 80 49 50 19 97 38 103 42 5 5
    // 26 51 45 103 18 53 37 96 40 96 4 0 21 -41 39 -91z"
    //         />
    //         <path
    //           d="M1215 590 c25 -28 150 -88 200 -95 27 -5 36 -3 26 3 -19 14 -5 32 27
    // 33 15 0 36 4 47 9 12 5 -5 6 -45 3 -78 -7 -154 8 -224 43 -46 23 -49 24 -31 4z"
    //         />
    //       </g>
    //     </svg>
    //     <svg
    //       version="1.0"
    //       xmlns="http://www.w3.org/2000/svg"
    //       width="300.000000pt"
    //       height="300.000000pt"
    //       viewBox="0 0 300.000000 300.000000"
    //       preserveAspectRatio="xMidYMid meet"
    //     >
    //       <metadata>
    //         Created by potrace 1.10, written by Peter Selinger 2001-2011
    //       </metadata>
    //       <g
    //         transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
    //         fill="#d3d3d3"
    //         stroke="none"
    //       >
    //         <path
    //           d="M0 1500 l0 -1500 1500 0 1500 0 0 1500 0 1500 -1500 0 -1500 0 0
    // -1500z m1747 1156 c98 -32 207 -101 281 -180 81 -85 189 -258 217 -348 50
    // -160 59 -481 16 -602 -12 -33 -35 -81 -52 -108 -38 -58 -32 -69 45 -88 l55
    // -13 36 32 c20 17 40 31 45 31 24 0 4 -20 -70 -73 -99 -71 -107 -84 -33 -50 82
    // 38 139 37 194 -3 83 -60 110 -159 73 -267 -20 -60 -29 -65 -29 -17 -1 76 -148
    // 222 -238 235 -43 7 -114 -13 -147 -41 -10 -9 -20 -10 -30 -4 -8 5 -10 10 -4
    // 10 7 0 18 20 25 45 9 31 18 44 27 40 47 -18 61 -16 93 13 32 29 32 30 11 36
    // -15 5 -32 0 -52 -14 -39 -28 -79 -21 -74 12 4 34 22 83 65 178 53 118 69 193
    // 69 337 0 137 -12 206 -46 274 -40 79 -59 92 -392 249 -92 44 -111 57 -107 71
    // 5 14 -10 30 -57 64 -121 89 -252 135 -337 120 -123 -21 -147 -84 -102 -265 12
    // -47 19 -97 16 -112 -8 -34 -58 -79 -173 -157 -131 -88 -206 -161 -243 -237
    // -29 -58 -31 -70 -26 -130 11 -137 121 -282 309 -409 48 -32 86 -60 84 -62 -1
    // -2 -21 3 -42 11 -22 7 -59 16 -84 20 l-45 7 45 -42 c45 -41 42 -44 -19 -21 -8
    // 3 -12 0 -9 -9 2 -8 46 -42 98 -76 195 -127 218 -153 140 -153 -22 0 -40 -4
    // -40 -9 0 -5 35 -31 77 -58 l78 -48 -35 -6 c-46 -7 -73 7 -144 75 -56 54 -59
    // 56 -82 42 -21 -15 -24 -14 -44 12 l-20 27 17 -39 18 -39 -25 -16 c-52 -34 -90
    // -74 -90 -94 0 -16 -3 -18 -12 -9 -12 12 -13 107 -2 174 5 31 14 42 55 67 46
    // 29 48 33 42 63 -4 24 -27 53 -88 110 -90 85 -135 149 -214 307 -98 194 -140
    // 367 -127 527 18 241 146 425 369 534 169 82 311 111 517 105 112 -2 149 -7
    // 217 -29z m829 -481 l37 -55 58 0 c33 0 59 -4 59 -9 0 -5 -16 -23 -36 -39 l-36
    // -30 31 -61 c17 -33 28 -61 25 -61 -3 0 -29 16 -56 36 l-49 37 -35 -34 -34 -33
    // 0 41 c0 68 -14 89 -77 122 l-58 30 63 1 62 0 0 55 c0 30 2 55 5 55 3 0 21 -25
    // 41 -55z m-444 -341 c9 -31 3 -99 -13 -150 -20 -63 -105 -114 -192 -114 l-40 0
    // 5 45 c5 41 14 53 109 148 57 56 108 102 113 102 6 -1 14 -15 18 -31z m-1002
    // -175 c108 -46 224 -81 328 -99 71 -12 73 -13 70 -38 -5 -40 -108 -72 -253 -79
    // -109 -5 -110 -5 -153 24 -73 50 -142 165 -127 213 9 28 29 25 135 -21z m-808
    // -400 l32 -13 -32 -8 c-28 -7 -32 -13 -32 -41 l-1 -32 -17 28 c-15 25 -21 27
    // -51 22 l-34 -7 17 27 c17 26 17 28 -5 51 -23 25 -14 30 26 15 15 -6 25 -1 40
    // 18 l20 26 3 -37 c3 -30 9 -38 34 -49z m1708 -183 c0 -9 -189 -163 -320 -261
    // -301 -224 -610 -409 -728 -436 -34 -7 -35 -7 -17 7 11 8 49 31 85 51 133 74
    // 345 215 525 350 102 76 190 139 196 139 5 1 19 2 32 3 13 0 68 35 123 76 100
    // 75 104 78 104 71z m-1277 -46 c16 0 17 -3 7 -15 -6 -8 -9 -23 -5 -34 5 -16 2
    // -18 -15 -14 -11 3 -27 -1 -36 -8 -15 -12 -16 -11 -10 8 5 17 2 23 -16 29 -23
    // 7 -23 7 5 16 15 5 27 17 27 26 0 12 3 13 12 4 7 -7 21 -12 31 -12z m1447 -30
    // c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-1013
    // -177 c-4 -3 -10 -3 -14 0 -3 4 0 7 7 7 7 0 10 -3 7 -7z m1178 -70 c84 -88 145
    // -251 129 -347 -10 -64 -20 -74 -27 -26 -9 65 -52 139 -148 251 -49 57 -89 108
    // -89 113 0 5 11 24 26 43 30 40 42 36 109 -34z m-1125 42 c-26 -25 -275 -171
    // -411 -240 -202 -103 -380 -176 -464 -191 -57 -10 -43 0 70 47 147 61 453 217
    // 604 308 72 44 146 82 164 84 17 2 36 5 42 5 5 1 3 -5 -5 -13z m-135 -5 c-3 -5
    // -13 -10 -21 -10 -8 0 -14 5 -14 10 0 6 9 10 21 10 11 0 17 -4 14 -10z m314
    // -16 c14 -12 -19 -1 -35 12 -18 14 -18 14 6 3 14 -6 27 -13 29 -15z m-75 0 c9
    // -3 16 -10 16 -14 0 -10 -142 -102 -148 -96 -7 7 19 50 51 84 30 32 50 38 81
    // 26z m861 -74 c3 -5 4 -10 1 -10 -3 0 -8 5 -11 10 -3 6 -4 10 -1 10 3 0 8 -4
    // 11 -10z m-677 -132 c-2 -13 -10 -30 -19 -39 -25 -25 -34 -137 -15 -185 l16
    // -39 -25 28 c-29 33 -45 85 -45 152 0 59 8 74 47 91 40 18 45 17 41 -8z m359
    // -66 c-24 -27 -21 -37 15 -47 36 -11 65 -52 55 -78 -6 -15 -9 -15 -43 -1 -34
    // 14 -38 14 -58 -5 -18 -17 -46 -28 -46 -18 0 1 11 29 25 62 13 33 25 70 25 83
    // 0 16 6 22 22 22 20 0 20 -1 5 -18z m172 -129 c25 -36 26 -41 6 -18 -26 30 -49
    // 65 -42 65 2 0 18 -21 36 -47z m91 -120 c0 -8 -5 -11 -11 -7 -9 5 -10 -8 -7
    // -47 l5 -54 -18 40 c-19 42 -23 72 -13 100 6 14 8 14 25 -1 11 -9 19 -23 19
    // -31z"
    //         />
    //       </g>
    //     </svg>
  );

  return (
    <Link
      component={RouterLink}
      to="/"
      color="inherit"
      aria-label="go to homepage"
      sx={{ lineHeight: 0 }}
    >
      <Box
        sx={{
          width: single ? 64 : 75,
          lineHeight: 0,
          cursor: "pointer",
          display: "inline-flex",
          ...sx,
        }}
      >
        {single ? singleLogo : fullLogo}
      </Box>
    </Link>
  );
}

export default memo(Logo);
