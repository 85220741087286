import _mock from "../_mock";

// ----------------------------------------------------------------------

export const _members = [...Array(8)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.name.fullName(index),
  role: _mock.role(index),
  photo: `/assets/images/portrait/portrait_${index + 1}.jpg`,

  socialLinks: {
    facebook: _mock.socials.facebook(index),
    instagram: _mock.socials.instagram(index),
    linkedin: _mock.socials.linkedin(index),
    twitter: _mock.socials.twitter(index),
  },
}));
