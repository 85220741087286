// @mui
import { styled, alpha } from "@mui/material/styles";
import {
  Link,
  Stack,
  Button,
  Container,
  TextField,
  Typography,
  TextFieldProps,
  Unstable_Grid2 as Grid,
  Checkbox,
  MenuItem,
  Select,
} from "@mui/material";
// utils
import { bgGradient } from "src/utils/cssStyles";
// components
import Iconify from "src/components/iconify";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useMemo, useState } from "react";
import { useAddUserMutation } from "src/generated/graphql";
import { useImmer } from "use-immer";
import { AddUserMutationVariables } from "src/generated/graphql";
import {
  MenuProps,
  menuItemStyle,
} from "src/sections/_career/job/filters/styles";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  ...bgGradient({
    color: alpha(theme.palette.grey[100], 0),
    imgUrl: "/assets/images/ninjatraininglogobrightness.png",
  }),
  padding: theme.spacing(10, 0),
  overflow: "hidden",
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(15, 0),
  },
}));

const StyledInput = styled((props: TextFieldProps) => (
  <TextField fullWidth {...props} />
))(({ theme }) => ({
  "& .MuiInputBase-input": {
    color: theme.palette.common.white,
  },
  "& .MuiInputLabel-root,& .MuiInputLabel-root.Mui-focused": {
    color: theme.palette.common.white,
  },
}));
const StyledSelect = styled((props: any) => <Select fullWidth {...props} />)(
  ({ theme }) => ({
    "& .MuiInputBase-input": {
      color: theme.palette.common.white,
    },
    "& .MuiInputLabel-root,& .MuiInputLabel-root.Mui-focused": {
      color: theme.palette.common.white,
    },
  })
);

// ----------------------------------------------------------------------

export default function LocationRequestForm() {
  const USER_TYPES = ["Instructor", "Student"];
  const STUDENT_BASE_SIZE = ["1-10", "11-25", "26-50", "50+"];
  let [email, setEmail] = useState("");
  let [first_name, setFirstName] = useState("");
  let [last_name, setLastName] = useState("");
  let [user_type, setUserType] = useState("");
  let [phone_number, setPhoneNumber] = useState("");
  let [zip_code, setZipCode] = useState("");
  // let [message, setMessage] = useState("");
  let [student_base_size, setStudentBaseSize] = useState("");
  // let [teaching_experience, setTeachingExperience] = useState("");
  let [weekly_usage, setWeeklyUsage] = useState("");
  // let [weekly_budget, setWeeklyBudget] = useState("");
  // let [error, setError] = useState(false);
  let [success, setSuccess] = useState(false);
  let addUserMutationVariables = useMemo<AddUserMutationVariables>(() => {
    return {
      first_name,
      last_name,

      id: uuidv4(),
      phone_number,
      zip_code,
      email,
      // message,
      user_type,
      // weekly_budget,
      student_base_size,
      // teaching_experience,
      weekly_usage,
    };
  }, [
    first_name,
    last_name,

    phone_number,
    zip_code,
    email,
    // message,
    user_type,
    // weekly_budget,
    student_base_size,
    // teaching_experience,
    weekly_usage,
  ]);
  const [
    commit,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useAddUserMutation({
    variables: addUserMutationVariables,
    onCompleted: () => {
      console.log("completed");
      setSuccess(true);
    },
  });

  useEffect(() => {
    if (mutationData) {
      console.log("mutationData", mutationData);
    }
  }, [mutationData]);

  return (
    <StyledRoot>
      <Container>
        <Grid
          container
          spacing={{
            xs: 5,
            md: 3,
          }}
          justifyContent="space-between"
        >
          <Grid xs={12} md={5}>
            <Typography
              variant="h1"
              component="h2"
              sx={{
                color: "#FFFFFF",
                mb: { xs: 3, md: 8 },
                textAlign: { xs: "center", md: "left" },
              }}
            >
              Bring Ninja Training to your neighborhood.
              <br />
            </Typography>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent={{ xs: "center", md: "flex-start" }}
              sx={{ color: "common.white", mb: 2 }}
            >
              <Iconify icon="carbon:email" width={24} sx={{ mr: 2 }} />

              <Link color="inherit" href="mailto:hello@example.com">
                info@ninjatraining.net
              </Link>
            </Stack>

            {/* <Stack
              direction="row"
              alignItems="center"
              justifyContent={{ xs: "center", md: "flex-start" }}
              sx={{ color: "common.white" }}
            >
              <Iconify icon="carbon:location" width={24} sx={{ mr: 2 }} />
              Coming Soon
            </Stack> */}
          </Grid>

          <Grid xs={12} md={5}>
            <Stack
              sx={{ color: "common.white", mb: 2 }}
              alignItems={{
                xs: "center",
                md: "flex-start",
              }}
            >
              <StyledSelect
                displayEmpty
                value={user_type}
                sx={{ mb: 2.5 }}
                onChange={(e: any) => {
                  setUserType(e.target.value);
                }}
                MenuProps={MenuProps}
                renderValue={(selected: any) => {
                  if (selected.length === 0) {
                    return (
                      <Typography
                        variant="body2"
                        sx={{ color: "text.disabled" }}
                        color={"#FFFFFF"}
                      >
                        I'm a...
                      </Typography>
                    );
                  }
                  return (
                    <Typography variant="subtitle2" component="span">
                      {selected}
                    </Typography>
                  );
                }}
              >
                {USER_TYPES.map((type) => (
                  <MenuItem key={type} value={type} sx={menuItemStyle}>
                    {/* <Checkbox
                      size="small"
                      disableRipple
                      checked={filterType.includes(type)}
                    /> */}
                    {type}
                  </MenuItem>
                ))}
              </StyledSelect>

              {user_type === "Instructor" && (
                <StyledSelect
                  displayEmpty
                  value={student_base_size}
                  sx={{ mb: 2.5 }}
                  onChange={(e: any) => {
                    setStudentBaseSize(e.target.value);
                  }}
                  MenuProps={MenuProps}
                  renderValue={(selected: any) => {
                    if (selected.length === 0) {
                      return (
                        <Typography
                          variant="body2"
                          sx={{ color: "text.disabled" }}
                          color={"#FFFFFF"}
                        >
                          Size of Student Base
                        </Typography>
                      );
                    }
                    return (
                      <Typography variant="subtitle2" component="span">
                        {selected}
                      </Typography>
                    );
                  }}
                >
                  {STUDENT_BASE_SIZE.map((type) => (
                    <MenuItem key={type} value={type} sx={menuItemStyle}>
                      {/* <Checkbox
                      size="small"
                      disableRipple
                      checked={filterType.includes(type)}
                    /> */}
                      {type}
                    </MenuItem>
                  ))}
                </StyledSelect>
              )}
              <StyledInput
                variant="filled"
                label="First Name"
                sx={{ mb: 2.5 }}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setFirstName(e.target.value);
                }}
              />
              <StyledInput
                variant="filled"
                label="Last Name"
                sx={{ mb: 2.5 }}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setLastName(e.target.value);
                }}
              />

              <StyledInput
                variant="filled"
                label="Email"
                sx={{ mb: 2.5 }}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setEmail(e.target.value);
                }}
              />

              <StyledInput
                variant="filled"
                label="Zip Code"
                sx={{ mb: 5 }}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setZipCode(e.target.value);
                }}
              />
              {/* {user_type === "Instructor" && (
                <StyledInput
                  variant="filled"
                  label="How long have you been teaching?"
                  sx={{ mb: 2.5 }}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setTeachingExperience(e.target.value);
                  }}
                />
              )} */}

              {/* {user_type === "Instructor" && (
                <StyledInput
                  variant="filled"
                  label="What's your ideal weekly budget?"
                  sx={{ mb: 2.5 }}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setWeeklyBudget(e.target.value);
                  }}
                />
              )} */}
              {user_type === "Instructor" && (
                <StyledInput
                  variant="filled"
                  label="How many hours per week do you need?"
                  sx={{ mb: 2.5 }}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setWeeklyUsage(e.target.value);
                  }}
                />
              )}
              <StyledInput
                variant="filled"
                label="Phone Number"
                sx={{ mb: 2.5 }}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setPhoneNumber(e.target.value);
                }}
              />

              <Button
                onClick={() => {
                  commit();
                }}
                disabled={mutationLoading || mutationError ? true : false}
                color={success ? "success" : "primary"}
                size="large"
                variant="contained"
                style={{ width: 450, height: 45 }}
              >
                {success
                  ? "Location Request Complete!"
                  : "Request New Location"}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </StyledRoot>
  );
}
