// _mock
import { _pricingHome, _tours } from "src/_mock";
// components
import ScrollProgress from "src/components/scroll-progress";
//
import PricingHome from "../../pricing/home";
import {
  HomeHero,
  HomeFAQs,
  HomeNewStart,
  HomeForDesigner,
  HomeCombination,
  HomeAdvertisement,
  HomeFeatureHighlights,
  HomeFlexibleComponents,
} from "../components";
import { MarketingLandingFreeSEO } from "src/sections/_marketing/landing";
import { MarketingAboutStory } from "src/sections/_marketing/about";
import {
  TravelLandingHero,
  TravelLandingSummary,
} from "src/sections/_travel/landing";
import RequestInfoFrom from "../components/RequestInfoForm";

// ----------------------------------------------------------------------

export default function InfoView() {
  return (
    <>
      <ScrollProgress />
      {/* <MarketingLandingFreeSEO /> */}
      {/* <HomeHero /> */}
      {/* <TravelLandingSummary /> */}
      {/* <HomeNewStart /> */}

      {/* <HomeFlexibleComponents /> */}

      {/* <HomeFeatureHighlights /> */}

      {/* <HomeForDesigner /> */}

      {/* <PricingHome plans={_pricingHome} /> */}

      {/* <HomeFAQs /> */}

      {/* <HomeCombination /> */}

      {/* <HomeAdvertisement /> */}
      {/* <TravelLandingHero tours={_tours.slice(0, 5)} /> */}
      {/* <MarketingAboutStory /> */}
      <RequestInfoFrom />
    </>
  );
}
