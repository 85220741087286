import { Helmet } from "react-helmet-async";
// sections
import InfoView from "src/sections/_home/view/InfoView";

// ----------------------------------------------------------------------

export default function MoreInfoPage() {
  return (
    <>
      <Helmet>
        <title>
          Ninja Training - Elevate Your MMA Business with Private Training Space
        </title>
      </Helmet>

      <InfoView />
    </>
  );
}
